import * as React from 'react'

export default class NotFound extends React.Component<{}, {}>  {

  constructor(props: {}, context: any) {
    super(props, context)
  }

  public componentDidMount() { 
    const location = `/?${window.location.search.substring(1)}`
    window.location.href = `https://unfound.page/?return=anvs.io&unfound=${window.location}`;
  }

  public render(): JSX.Element | null { 
    return null;
  }
  
}
